import React, { useCallback, useEffect, useState } from 'react';
import { Col, Modal } from 'react-bootstrap';
import { change, Field } from 'redux-form';
import Toggle from 'react-toggle';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { format } from 'date-fns';
import companyModulesRepository from 'repositories/CompanyModules';
import { useAuth } from 'contexts/auth';
import { percentMask } from '../../../../../utils/masks';
import renderField from '../../../../../components/RenderField';
import positionsRepository from '../../../../../repositories/Positions';
import employeesRepository from 'repositories/Employees';
import {
  cpfMask,
  RgMask,
  name,
  email,
  phoneMask,
  description,
} from 'client/components/ToNormalize/ToNormalize';
import FormPosition from '../../../Positions/NewPosition/FormPosition';
import { faCircleUser } from '@fortawesome/free-solid-svg-icons';
import DropPhotoContainer from 'client/views/MyCompanyData/Form/DropPhotoContainer';

const EmployeeMainData = () => {
  const [handleOpenModal, setHandleOpenModal] = useState(false);
  const [positions, setPositions] = useState([]);
  const [isIndicatorsModuleActive, setIsIndicatorsModuleActive] =
    useState(false);
  const [employeeWithIndicatorActive, setEmployeeWithIndicatorActive] =
    useState(false);

  const { companyId, company } = useAuth();
  const dispatch = useDispatch();

  const { isActive, sendSMSIndicators, cellPhone, photo } = useSelector(
    (state) => state.form.formEmployee.values
  );

  const verifyModuleAndEmployees = async () => {
    const modulesResponse = await companyModulesRepository.getCompanyModules({
      companyId,
      moduleId: 6,
    });

    if (modulesResponse.data[0].status === 3) setIsIndicatorsModuleActive(true);

    const employeeResponse = await employeesRepository.getAllByCompany(
      companyId,
      {
        isActive: 1,
      }
    );

    const isSMSIndicatorActive = employeeResponse?.data?.filter(
      (emp) => emp.sendSMSIndicators === true
    );

    if (isSMSIndicatorActive.length !== 0) setEmployeeWithIndicatorActive(true);
  };

  useEffect(() => {
    verifyModuleAndEmployees();
  }, []);

  useEffect(() => {
    if (companyId) loadPositions(companyId);
  }, [companyId]);

  const handleToggle = () => {
    dispatch(change('formEmployee', 'isActive', !isActive));
  };

  const handleIndicatorToggle = () => {
    if (!cellPhone) {
      return toastr.warning(
        'Não foi possível ativar a função',
        'O disparo de indicadores é possível apenas quando o telefone celular está preenchido'
      );
    }

    if (!employeeWithIndicatorActive || sendSMSIndicators) {
      dispatch(change('formEmployee', 'sendSMSIndicators', !sendSMSIndicators));
    } else {
      return toastr.warning(
        'Não foi possível ativar a função',
        'O disparo de indicadores é possível apenas para 1 funcionário. Verifique e tente novamente'
      );
    }
  };

  const loadPositions = async () => {
    try {
      const { data } = await positionsRepository.getAllByCompany(companyId, {
        isActive: 1,
      });
      setPositions(data);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao carregar os cargos. Por favor, tente novamente'
      );
    }
  };

  const handlePositionModal = async () => {
    setHandleOpenModal(true);
  };

  const registerPositionSuccess = (positionId) => {
    setHandleOpenModal(false);
    dispatch(change('formEmployee', 'positionId', positionId));

    loadPositions(companyId);
  };

  const handleValidateFiles = (files, quantity) => {
    if (files.length > quantity) {
      toastr.warning(
        `Selecione somente ${quantity} ${quantity === 1 ? 'imagem' : 'imagens'}`
      );
      return false;
    }
    return true;
  };

  const createFileObject = (file) => ({
    file: file,
    name: `${company.Cpf_Cnpj}-${file.name}`,
    preview: URL.createObjectURL(file),
    uploaded: false,
  });

  const handleUploadPhoto = async (files) => {
    let validateFiles = handleValidateFiles(files, 1);

    if (!validateFiles) return;
    const file = createFileObject(files[0]);

    if (photo && photo.uploaded)
      dispatch(change('formEmployee', 'photoToDelete', photo));

    dispatch(change('formEmployee', 'photo', file));
  };

  const handleRemovePhoto = () => {
    if (photo && photo.uploaded)
      dispatch(change('formEmployee', 'photoToDelete', photo));

    dispatch(change('formEmployee', 'photo', null));
  };

  return (
    <>
      <Col>
        <Col xs={12} sm={6} md={3} lg={3}>
          <Field
            label="Nome Completo:"
            name="name"
            component={renderField}
            maxLength="50"
            normalize={name}
            required
          />
        </Col>
        <Col xs={12} sm={6} md={3} lg={3}>
          <Field
            label="CPF:"
            name="cpf"
            component={renderField}
            normalize={cpfMask}
            required
          />
        </Col>
        <Col xs={12} sm={6} md={3} lg={3}>
          <Field
            label="RG:"
            name="rg"
            component={renderField}
            normalize={RgMask}
          />
        </Col>
        <Col xs={12} sm={2} md={2} lg={2}>
          <Field
            label="Data de Nascimento:"
            name="birthDate"
            placeholder="dd/mm/aaaa"
            component={renderField}
            type="date"
          />
        </Col>
        <Col xs={12} sm={1} md={1} lg={1}>
          <label>Status</label>
          <div style={{ paddingLeft: '10px' }}>
            <Toggle checked={isActive} onChange={handleToggle} />
          </div>
        </Col>
      </Col>

      <Col>
        <Col xs={12} sm={6} md={2} lg={2}>
          <Field
            label="Telefone Residencial:"
            name="homePhone"
            component={renderField}
            normalize={phoneMask}
          />
        </Col>
        <Col xs={12} sm={6} md={2} lg={2}>
          <Field
            label="Telefone Celular:"
            name="cellPhone"
            component={renderField}
            normalize={phoneMask}
          />
        </Col>
        <Col xs={12} sm={6} md={3} lg={3}>
          <div className="div-a-modal">
            <a onClick={() => handlePositionModal()}>Adicionar Cargo</a>
          </div>
          <Field
            label="Cargo:"
            name="positionId"
            component={renderField}
            as="select"
          >
            <option name="" value="">
              Selecione
            </option>
            {positions.map((e) => {
              return (
                <option key={e.id} value={e.id}>
                  {e.description}
                </option>
              );
            })}
          </Field>
        </Col>
        <Col xs={12} sm={6} md={2} lg={2}>
          <Field
            label="% Comissão:"
            name="comissionRate"
            component={renderField}
            {...percentMask}
            maxLength="7"
          />
        </Col>
        <Col xs={12} sm={6} md={3} lg={3}>
          <Field
            label="Email:"
            name="email"
            component={renderField}
            normalize={email}
          />
        </Col>
      </Col>

      <Col>
        <Col xs={12} sm={2} md={2} lg={2}>
          <Field
            label="Data de Admissão:"
            name="admissionDate"
            placeholder="dd/mm/aaaa"
            component={renderField}
            type="date"
          />
        </Col>

        <Col xs={12} sm={2} md={2} lg={2}>
          <Field
            label="Data de Demissão:"
            name="resignationDate"
            placeholder="dd/mm/aaaa"
            component={renderField}
            type="date"
          />
        </Col>
        <Col xs={12} sm={6} md={3} lg={3}>
          <Field
            label="Nome Completo da Mãe:"
            name="motherName"
            component={renderField}
          />
        </Col>

        {isIndicatorsModuleActive && (
          <Col xs={12} sm={2} md={2} lg={3}>
            <label>Enviar Indicadores por SMS</label>
            <div style={{ paddingLeft: '70px' }}>
              <Toggle
                checked={sendSMSIndicators}
                onChange={() => handleIndicatorToggle()}
              />
            </div>
          </Col>
        )}
      </Col>

      <Col lg={6} md={6} sm={6} xs={6}>
        <Field
          label="Observações:"
          name="observations"
          component={renderField}
          maxLength="500"
          as="textarea"
          style={{ height: '100px' }}
        />
      </Col>

      <Col lg={2} md={2} sm={3} xs={12}>
        <label>
          <strong>Foto (Perfil): </strong>
        </label>
        <div style={{ width: 'fit-content' }}>
          <DropPhotoContainer
            image={photo}
            height="100px"
            width="100px"
            label="Adicionar foto"
            icon={faCircleUser}
            onRemove={handleRemovePhoto}
            onUpload={handleUploadPhoto}
          />
        </div>
      </Col>

      {handleOpenModal && (
        <Modal
          dialogClassName="modal-formProduto"
          show={handleOpenModal}
          onHide={() => setHandleOpenModal(false)}
          animation
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <strong>Novo Cargo</strong>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormPosition
              modal
              handleModal={(positionId) => registerPositionSuccess(positionId)}
              hideModal={() => setHandleOpenModal(false)}
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default EmployeeMainData;
