import React from 'react';
import { RankingIndicatorsHeader } from './RankingIndicatorsHeader';
import useManagementIndicatorsStore from '../../store/management-indicators-store';
export default function RankingIndicators() {
  const { currentDate } = useManagementIndicatorsStore((store) => {
    return {};
  });
  return (
    <div style={{ marginTop: '1rem' }}>
      <RankingIndicatorsHeader />
    </div>
  );
}
