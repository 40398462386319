import React, { useState, useEffect } from 'react'
import ReactTable from 'react-table'
import Card from 'components/Card/Card.jsx'
import DownloadXlsButton from 'components/DownloadXlsButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons'
import { format } from 'date-fns'

import '../styles.css'

import productsRepository from '../../../../../repositories/Products'
import { useAuth } from 'contexts/auth'
import { toastr } from 'react-redux-toastr'

const NoBrandAndFamily = () => {
  // Loadings
  const [loading, setLoading] = useState(false)
  const [loadingAllProds, setLoadingAllProds] = useState(false)
  // Page
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  // Filtro do Select
  const [noBrandsOrFamily, setNoBrandsOrFamily] = useState(0)
  // Produtos renderizados na tabela
  const [products, setProducts] = useState([])
  const [filteredProducts, setFilteredProducts] = useState([])

  const { companyId } = useAuth()

  useEffect(() => {
    loadProucts({
      page: 0,
      pageSize,
    })
  }, [])

  useEffect(() => {
    filterResult(noBrandsOrFamily)
  }, [noBrandsOrFamily, products])

  async function loadProucts(state) {
    setLoading(true)
    try {
      const response = await productsRepository.getAllPaginated({
        Company_id: companyId || 0,
        page: state.page + 1,
        limit: state.pageSize,
        noBrandsOrFamily: true,
      })

      const formatedProduct = response.data.map((product) => {
        return {
          ...product,
          Code: Number(product.Code),
          createdAt: format(new Date(product.createdAt), 'dd/MM/yyyy'),
        }
      })

      const total = response.headers['x-total-count']
      setPages(Math.floor(total / state.pageSize) + 1)

      setProducts(formatedProduct)
    } catch (err) {
      return toastr.warning(
        'Houve um erro ao carregar os produtos sem marca e família, tente novamente'
      )
    } finally {
      setLoading(false)
    }
  }

  async function filterResult() {
    // Sem Marca e Familia
    if (noBrandsOrFamily === 0) {
      setFilteredProducts(
        products.filter((product) => !product.Brand_id && !product.Family_id)
      )
    }
    // Sem Marca
    if (noBrandsOrFamily === 1) {
      setFilteredProducts(products.filter((product) => !product.Brand_id))
    }
    // Sem Familia
    if (noBrandsOrFamily === 2) {
      setFilteredProducts(products.filter((product) => !product.Family_id))
    }
  }

  return (
    <Card
      content={
        <div id="page-stock-list">
          <header>
            <div>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <strong>Produto: </strong>
              </span>
              <select
                className="form-control foco-input select-families"
                style={{ marginBottom: '20px', width: '20%' }}
                onChange={(e) => setNoBrandsOrFamily(e.target.value)}
                value={noBrandsOrFamily}
              >
                <option value={0}>Sem Marca e Família</option>
                <option value={1}>Sem Marca</option>
                <option value={2}>Sem Família</option>
              </select>
            </div>
          </header>
          <main>
            <ReactTable
              style={{ fontWeight: 'bold', textAlign: 'center' }}
              data={filteredProducts}
              columns={[
                {
                  Header: 'Código',
                  accessor: 'Code',
                },
                {
                  Header: 'Descrição',
                  accessor: 'Description',
                },
                {
                  Header: 'Estoque',
                  accessor: 'Stock_Quantity',
                },
                {
                  Header: 'Ref. Fabricante',
                  accessor: 'Manufacturer_Ref',
                },
                {
                  Header: 'Data de Cadastro',
                  accessor: 'createdAt',
                },
              ]}
              defaultPageSize={10}
              loading={loading}
              showPagination
              sortable
              showPaginationTop={false}
              showPaginationBottom
              pageSizeOptions={[5, 10, 20, 50, 100]}
              pages={pages}
              manual
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando..."
              pageText="Página"
              ofText="de"
              rowsText="linhas"
              noDataText={`Não há produtos sem ${
                noBrandsOrFamily === 0
                  ? 'família e marca'
                  : noBrandsOrFamily === 1
                  ? 'marca'
                  : 'familia'
              }`}
              onFetchData={(state) =>
                loadProucts({
                  ...state,
                })
              }
              onPageChange={(page) => setPage(page)}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            />
          </main>
        </div>
      }
    />
  )
}

export default NoBrandAndFamily
