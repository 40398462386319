import create from 'zustand';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

const useManagementIndicatorsStore = create((set, get) => ({
  selectedOption: 'Financeiro',
  currentDate: new Date(),

  setSelectedOption: (newOption) => set({ selectedOption: newOption }),

  getCurrentMonthAndYear: () => {
    const { currentDate } = get();
    if (currentDate instanceof Date && !isNaN(currentDate)) {
      const monthName = format(currentDate, 'MMMM', { locale: ptBR });
      const capitalizedMonthName =
        monthName.charAt(0).toUpperCase() + monthName.slice(1);
      const year = format(currentDate, 'yyyy', { locale: ptBR });
      return { monthName: capitalizedMonthName, year };
    }
  },
  updateCurrentDate: (newDate) => {
    set({
      currentDate: newDate,
    });
  },
}));

export default useManagementIndicatorsStore;
