import React from 'react';
import { RankingIndicatorsNavigator } from './RankingIndicatorsNavigator';
import useManagementIndicatorsStore from '../../store/management-indicators-store';
export function RankingIndicatorsHeader() {
  const { getCurrentMonthAndYear } = useManagementIndicatorsStore((store) => {
    return {
      getCurrentMonthAndYear: store.getCurrentMonthAndYear,
    };
  });

  const { monthName, year } = getCurrentMonthAndYear();

  return (
    <div>
      <div>
        <p style={{ color: '#1D7391', fontSize: '20px' }}>
          {`RANKINGS 🏆 (${monthName} de ${year})`}
        </p>
      </div>
      <RankingIndicatorsNavigator />
    </div>
  );
}
