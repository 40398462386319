import React, { useEffect, useState } from 'react';
import { change, reduxForm, Field, FieldArray } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Modal } from 'react-bootstrap';

import Input from 'client/views/Clients/NewClient/FormClient/components/Input';
import AlertModal from 'components/AlertModal/AlertModal';
import Button from '../../components/CustomButton/CustomButton';
import DevolutionOptionsModal from './DevolutionOptionsModal';

import salesRepository from '../../../repositories/Sales';
import customerCreditRepository from 'repositories/CustomerCredit';
import companyConfigRepository from 'repositories/CompanyConfig';
import partsRequisitionRepository from 'repositories/PartsRequisition';

import { getProductsUnderStock } from 'client/components/MinStockAlertButton/redux/actions';
import { useAuth } from 'contexts/auth';

import { quantityMask } from '../../../utils/masks';
import { currency, encrypt } from '../../components/ToNormalize/ToNormalize';
import { usePlanSignatureContext } from 'contexts/plan-signature';

const TableField = ({ field, quantity, partsRequisition }) => {
  const handleQuantity = (value) => {
    if (
      quantityMask.normalize(value) > quantity &&
      partsRequisition !== 'Requisição de Peças'
    )
      return toastr.warning(
        'Quantidade deve ser menor ou igual a quantidade atual.'
      );
  };

  return (
    <Field
      name={`${field}.newQuantityToReturn`}
      component={Input}
      {...quantityMask}
      onChange={(e) => handleQuantity(e.target.value)}
      disabled={!quantity && partsRequisition !== 'Requisição de Peças'}
    />
  );
};

const Table = ({ fields, partsRequisition }) => {
  return (
    <table>
      <thead>
        <tr>
          <th>Código</th>
          <th>Produto</th>
          <th>Qtd. Original</th>
          {partsRequisition === 'Requisição de Peças' && <th>Qtd. Entregue</th>}
          <th>Qtd. Atual</th>
          <th>Qtd. a Devolver</th>
        </tr>
      </thead>
      <tbody>
        {fields.map((field, index, items) => {
          const quantity =
            partsRequisition === 'Requisição de Peças'
              ? !items.get(index).deliveredQuantity
                ? 0
                : items.get(index).deliveredQuantity -
                  items.get(index).QuantityItemsReturned
              : items.get(index).Quantity -
                items.get(index).QuantityItemsReturned;
          return (
            <tr>
              <td>{items.get(index).Products.Code}</td>
              <td>{items.get(index).Products.Description}</td>
              <td>{items.get(index).Quantity}</td>
              {partsRequisition === 'Requisição de Peças' && (
                <td>{items.get(index).deliveredQuantity}</td>
              )}
              <td>
                <span
                  style={{
                    color:
                      items.get(index).QuantityItemsReturned > 0 ? 'red' : '',
                  }}
                >
                  {quantity}
                </span>
              </td>
              <td style={{ display: 'flex', justifyContent: 'center' }}>
                <TableField
                  field={field}
                  quantity={quantity}
                  partsRequisition={partsRequisition}
                  value={items.get(index).newQuantityToReturn}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

function DevolutionModal({ sale, show, onHide, loadSales }) {
  const [loading, setLoading] = useState(false);
  const [isCanceled, setIsCanceled] = useState(false);
  const [isDevolutionPartial, setIsDevolutionPartial] = useState(false);
  const [isTotalDevolutionModalOpen, setIsTotalDevolutionModalOpen] =
    useState(false);
  const [partsRequisitionInfo, setPartsRequisitionInfo] = useState('');

  const [isDevolutionOptionsModalOpen, setIsDevolutionOptionsModalOpen] =
    useState(false);
  const [devolutionComplete, setDevolutionComplete] = useState(false);

  const { isPlanFree, isPlanStart, isPlanBasic } = usePlanSignatureContext();
  const isPlanPrime = !isPlanFree && !isPlanStart && !isPlanBasic;

  const isSaleCanceled = sale.Status === 'Cancelado(a)';

  const { products } = useSelector(
    (state) => state.form.devolutionModal.values
  );

  const hasBeenEdited = products.filter(
    (prod) => !!prod.Quantity && !!prod.newQuantityToReturn
  ).length;
  const hasItemsAbleToReturn =
    products.filter((prod) => prod.Quantity !== prod.QuantityItemsReturned)
      .length > 0;
  const hasProductsWithoutDelivered = products.filter(
    (prod) => prod.deliveredQuantity === 0
  ).length;

  const hasProductsWithDelivered =
    products.filter((prod) => prod.deliveredQuantity > 0).length > 0;

  const dispatch = useDispatch();

  const { companyId, userId } = useAuth();

  useEffect(() => {
    if (!!companyId) {
      loadProducts();
      getPartsRequisitionConfiguration();
    }
  }, [companyId]);

  const loadProducts = async () => {
    setLoading(true);
    try {
      let onlyProducts = sale.SalesItems.filter((p) => p.Type === 'Produto');

      const deliveredInfo = await partsRequisitionRepository.getSaleRequisition(
        sale.id
      );

      onlyProducts = onlyProducts.map((saleItem) => {
        const deliveredData = deliveredInfo.saleRequisitionItems.find(
          (item) => saleItem.Products.id === item.productId
        );

        return {
          ...saleItem,
          deliveredQuantity: deliveredData.deliveredQuantity || 0,
        };
      });

      dispatch(change('devolutionModal', 'products', onlyProducts));
    } catch (err) {
      return toastr.error(
        'Ocorreu um erro ao carregar os produtos. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  const verify = async () => {
    let productsEdited = products.filter((x) => x.newQuantityToReturn > 0);
    if (productsEdited.length > 0) {
      setIsCanceled(true);
    } else {
      onHide();
    }
  };

  const submit = async () => {
    let productsEdited = products.filter(
      (product) => product.newQuantityToReturn > 0
    );
    let productsAbleToReturn = products.filter(
      (product) => product.Quantity > 0
    );

    if (productsEdited.length <= 0) {
      return toastr.warning('Nenhum item foi preenchido para a devolução.');
    }

    const data = await companyConfigRepository.show(companyId);

    if (data.stockWriteOff === 'Requisição de Peças') {
      const notPermited = productsEdited.filter(
        (item) => item.newQuantityToReturn > item.deliveredQuantity
      ).length;

      if (notPermited)
        return toastr.warning(
          'Não é possível devolver mais itens que a quantidade entregue.'
        );
    }
    let totalDevolution = productsEdited.filter(
      (product) =>
        product.Quantity -
          (product.newQuantityToReturn + product.QuantityItemsReturned) ===
        0
    );

    if (
      totalDevolution.length === productsAbleToReturn.length &&
      (sale.Type === 'Venda' || sale.Type === 'PDV')
    ) {
      setIsTotalDevolutionModalOpen(true);
    } else {
      setIsDevolutionPartial(true);
    }
  };
  const sendDevolution = async (items, customSuccessMessage) => {
    setLoading(true);
    const defaultSuccessMessage = 'Devolução efetuada com sucesso';

    try {
      let discountedItems;

      const discountPerItem =
        sale.Discount_Type === 'R$'
          ? sale.Discount_Value / items.length
          : (sale.Discount_Value_Percentage / 100) *
            (sale.Product_Value / items.length);

      discountedItems = items.map((item) => ({
        ...item,
        Amount:
          sale.Discount_Type === 'R$'
            ? item.Amount - discountPerItem
            : item.Amount -
              item.Amount * (sale.Discount_Value_Percentage / 100),
      }));

      await salesRepository.devolution(
        sale.id,
        discountedItems,
        userId,
        devolutionComplete
      );

      if (customSuccessMessage) {
        toastr.success(defaultSuccessMessage, customSuccessMessage);
      } else {
        toastr.success(defaultSuccessMessage);
      }

      dispatch(getProductsUnderStock(companyId));
      onHide();
      loadSales();
    } catch (err) {
      return toastr.error('Ocorreu um erro ao devolver os itens');
    } finally {
      setLoading(false);
    }
  };

  const handleCloseDevolutionOptionsModal = () =>
    setIsDevolutionOptionsModalOpen(false);

  const handleReturnParcialItems = () => {
    const items = products.filter((item) => item.newQuantityToReturn);
    return handleDevolution(items);
  };

  const handleCalculateReturnedProductValues = () => {
    const totalValue = products.reduce(
      (sum, product) =>
        sum + Number(product.Amount) * Number(product.newQuantityToReturn),
      0
    );
    if (sale.Discount_Value) {
      const ValueWithDiscount =
        sale.Discount_Type === 'R$'
          ? totalValue - sale.Discount_Value
          : totalValue * (sale.Discount_Value_Percentage / 100);
      return ValueWithDiscount;
    }
    return totalValue;
  };

  const handleDevolutionWithCreditGeneration = async () => {
    setIsDevolutionOptionsModalOpen(false);
    setLoading(true);

    try {
      const creditToGenerate = handleCalculateReturnedProductValues();

      await customerCreditRepository.create({
        customerId: sale.Customer.id,
        value: creditToGenerate,
        type: 'Entrada',
        reason: 'Devolução',
        observations: null,
      });

      await sendDevolution(
        products,
        'Foi realizada a devolução de estoque e gerado um crédito para o cliente'
      );
    } catch (err) {
      console.log(err);
      toastr.error(
        'Ocorreu um erro',
        'Houve um problema ao realizar a devolução. Tente novamente!'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleReturnAllItems = () => {
    setDevolutionComplete(true);
    const items = [];
    products.map((item) => {
      if (!item.QuantityItemsReturned) {
        items.push({
          ...item,
          newQuantityToReturn: item.Quantity,
        });
      } else if (
        item.QuantityItemsReturned &&
        item.Quantity !== item.QuantityItemsReturned
      ) {
        items.push({
          ...item,
          newQuantityToReturn: item.Quantity - item.QuantityItemsReturned,
        });
      }
    });
    return handleDevolution(items);
  };

  const handleDevolution = (items) => {
    dispatch(change('devolutionModal', 'products', items));
    if (!isPlanPrime) return sendDevolution(items);
    return setIsDevolutionOptionsModalOpen(true);
  };

  const handleOpenReport = () => {
    const saleIdHash = encrypt(sale.id, '@OS-dig:saleId');
    window.open(
      window.location.origin + `/report/sale-returns?saleId=${saleIdHash}`,
      '_blank'
    );
  };

  const getPartsRequisitionConfiguration = async () => {
    const data = await companyConfigRepository.show(companyId);
    setPartsRequisitionInfo(data.stockWriteOff);
  };

  return (
    <>
      <Modal dialogClassName="modal-90w" show={show} animation={true}>
        <Modal.Header closeButton onHide={onHide}>
          <Modal.Title>
            <div className="survey-modal-header">
              <h4>
                <strong>Devolução</strong>
              </h4>
              <h6>
                <strong>{sale.Type}: </strong>
                {sale.Code}
              </h6>
              <h6>
                <strong>Valor: </strong>
                {currency(sale.Product_Value)}
              </h6>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span
            style={{
              color: 'red',
              display: 'flex',
              justifyContent: 'flex-end',
              marginBottom: '10px',
              marginRight: '5px',
            }}
          >
            * Quantidade atual em vermelho demonstra que já houve devolução
          </span>

          <div className="survey-modal-body">
            <FieldArray
              name="products"
              component={Table}
              partsRequisition={partsRequisitionInfo}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            bsStyle="warning"
            disabled={loading}
            fill
            onClick={handleOpenReport}
          >
            Histórico de Devolução
          </Button>
          <Button
            bsStyle="danger"
            disabled={loading}
            fill
            onClick={() => verify()}
          >
            Cancelar
          </Button>
          <Button
            bsStyle="primary"
            disabled={
              loading ||
              !hasItemsAbleToReturn ||
              isSaleCanceled ||
              (partsRequisitionInfo === 'Requisição de Peças' &&
                hasProductsWithoutDelivered > 0)
            }
            fill
            onClick={() => setIsTotalDevolutionModalOpen(true)}
          >
            Devolver Todos os Itens
          </Button>
          <Button
            bsStyle="success"
            disabled={
              loading ||
              !hasBeenEdited ||
              isSaleCanceled ||
              (partsRequisitionInfo === 'Requisição de Peças' &&
                !hasProductsWithDelivered)
            }
            fill
            onClick={() => submit()}
          >
            Devolver Itens
            <span className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''} />
          </Button>
        </Modal.Footer>
      </Modal>

      <AlertModal
        show={isCanceled}
        message="Deseja cancelar a devolução? Os itens não serão devolvidos para o estoque se a devolução não for realizada."
        onHide={() => setIsCanceled(true)}
        onCancel={() => setIsCanceled(false)}
        onSubmit={() => onHide()}
      />

      <AlertModal
        show={isDevolutionPartial}
        message="Deseja confirmar a devolução parcial dos itens? O processo de devolução é irreversível."
        onHide={() => setIsDevolutionPartial(false)}
        onCancel={() => setIsDevolutionPartial(false)}
        onSubmit={handleReturnParcialItems}
        loading={loading}
      />

      <AlertModal
        show={isTotalDevolutionModalOpen}
        message="Deseja realizar a devolução de todos os itens da venda? Ao efetuar a devolução total, a venda será cancelada, juntamente com qualquer título vinculado."
        onHide={() => setIsTotalDevolutionModalOpen(false)}
        onCancel={() => setIsTotalDevolutionModalOpen(false)}
        onSubmit={handleReturnAllItems}
        loading={loading}
      />

      {isDevolutionOptionsModalOpen && (
        <DevolutionOptionsModal
          isOpen={true}
          setIsDevolutionOptionsModalOpen={setIsDevolutionOptionsModalOpen}
          dispatch={dispatch}
          handleReturnItems={(message) => {
            handleCloseDevolutionOptionsModal();
            sendDevolution(products, message);
          }}
          handleDevolutionWithCreditGeneration={
            handleDevolutionWithCreditGeneration
          }
          handleCalculateReturnedProductValues={
            handleCalculateReturnedProductValues
          }
        />
      )}
    </>
  );
}

export default reduxForm({
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  form: 'devolutionModal',
})(DevolutionModal);
