import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons';

const renderDragMessage = (isDragActive, isDragReject, label) => {
  if (!isDragActive) return <span>{label}</span>;

  if (isDragReject)
    return <span style={{ color: 'red' }}>Arquivo não Suportado</span>;

  return <span style={{ color: 'green' }}>Arquivos suportados</span>;
};

export default function UploadContainer({
  getRootProps,
  getInputProps,
  isDragActive,
  isDragReject,
  image,
  onRemove,
  height = '120px',
  width = '120px',
  icon = faPlusCircle,
  label = 'Adicionar logo',
}) {
  return (
    <>
      {!image?.preview ? (
        <div
          {...getRootProps()}
          className="container-add-image"
          style={{ width: width, height: height }}
        >
          <FontAwesomeIcon icon={icon} />
          <br />
          {renderDragMessage(isDragActive, isDragReject, label)}

          <input {...getInputProps()} />
        </div>
      ) : (
        <div>
          <div
            className="container-show-image"
            style={{ width: width, height: height }}
          >
            <FontAwesomeIcon icon={faTimes} onClick={onRemove} />
            <img src={image?.preview} alt={image.name} />
          </div>

          <center>
            <button {...getRootProps()} type="button" className="btn-link">
              <input {...getInputProps()} />
              Alterar
            </button>
          </center>
        </div>
      )}
    </>
  );
}
