import React from 'react';
import { IndicatorCard } from '../../components/IndicatorCard';
import {
  ButtonPreviousOrNext,
  ContainerContentCard,
  ContainerHeaderCardMonth,
  ContainerGraph,
  ContainerGraphHeader,
  ContainerGraphContent,
  ContainerSalesScreen,
  TextHeaderCardTitle,
} from './Sales.styles';
import { useAuth } from 'contexts/auth';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import ManagementIndicatorRepository from 'v2/repositories/ManagementIndicatorRepository';
import { useEffect } from 'react';
import { IndicatorGraph } from '../../components/IndicatorGraph';
import { InputSelectDefault } from 'v2/components/Input';
import { toastr } from 'react-redux-toastr';
import { RankingIndicatorsNavigator } from '../../components/RankingIndicators/RankingIndicatorsNavigator';
import RankingIndicators from '../../components/RankingIndicators/RankingIndicators';
import useManagementIndicatorsStore from '../../store/management-indicators-store';

export function Sales() {
  const { companyId } = useAuth();
  const [isLoading, setLoading] = useState(true);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedMonth, setSelectedMonth] = useState(
    currentDate.getMonth() + 1
  );
  const [selectedYear, setSelectedYear] = useState(currentDate.getFullYear());
  const [orcaments, setOrcaments] = useState({});
  const [orderServices, setOrderServices] = useState({});
  const [orderServicesTickets, setOrderServicesTickets] = useState(0);
  const [salesPDVTickets, setSalesPDVTickets] = useState(0);
  const [productsSold, setProductsSold] = useState(0);
  const [servicesSold, setServicesSold] = useState(0);

  const [revenuePeriod, setRevenuePeriod] = useState({
    label: 'Últimos 6 meses',
    value: 6,
  });
  const [revenueData, setRevenueData] = useState([]);
  const [revenueLoading, setRevenueLoading] = useState(true);

  const [avarageTicketPeriod, setAvarageTicketPeriod] = useState({
    label: 'Últimos 6 meses',
    value: 6,
  });
  const [avarageTicketData, setAvarageTicketData] = useState([]);
  const [avarageTicketLoading, setAvarageTicketLoading] = useState(true);

  const { updateCurrentDate } = useManagementIndicatorsStore((store) => {
    return {
      updateCurrentDate: store.updateCurrentDate,
    };
  });

  function formatDateRange(date) {
    const today = new Date();
    let startDate = new Date(today.getFullYear(), today.getMonth() - date, 1);

    const startDateFormat = startDate.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });

    const endDate = today;
    const endDateFormat = endDate.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });

    return `${startDateFormat} - ${endDateFormat}`;
  }

  const fetchOrcamentOS = async () => {
    setLoading(true);
    try {
      const date = `${selectedYear}-${String(selectedMonth).padStart(2, '0')}`;
      const { orcament, os } =
        await ManagementIndicatorRepository.getOrcamentsOS(companyId, date);
      setOrcaments(orcament);
      setOrderServices(os);
      setLoading(true);
    } catch (err) {
      toastr.error('Ocorreu um erro ao buscar os dados');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchTickets = async () => {
    setLoading(true);
    try {
      const formattedDate = `${selectedYear}-${String(selectedMonth).padStart(
        2,
        '0'
      )}`;
      const { averageSalesTicket, averageserviceOrdersTicket } =
        await ManagementIndicatorRepository.getAverageTicket(
          companyId,
          formattedDate
        );
      setSalesPDVTickets(averageSalesTicket);
      setOrderServicesTickets(averageserviceOrdersTicket);
    } catch (err) {
      toastr.error('Ocorreu um erro ao buscar os dados');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchItemsSold = async () => {
    setLoading(true);
    try {
      const date = `${selectedYear}-${String(selectedMonth).padStart(2, '0')}`;
      const { totalProduct, totalService } =
        await ManagementIndicatorRepository.getItemsSold(companyId, date);
      setProductsSold(totalProduct);
      setServicesSold(totalService);
    } catch (err) {
      toastr.error('Ocorreu um erro ao buscar os dados');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  const fetchRevenueGraph = async () => {
    setRevenueLoading(true);
    try {
      const response = await ManagementIndicatorRepository.getRevenueGraph(
        companyId,
        revenuePeriod.value
      );
      setRevenueData(response);
    } catch (err) {
      toastr.error('Ocorreu um erro ao buscar os dados');
      console.error(err);
    } finally {
      setRevenueLoading(false);
    }
  };

  const fetchAverageTicketGraph = async () => {
    setAvarageTicketLoading(true);
    try {
      const response =
        await ManagementIndicatorRepository.getAverageTicketGraph(
          companyId,
          avarageTicketPeriod.value
        );
      setAvarageTicketData(response);
    } catch (err) {
      toastr.error('Ocorreu um erro ao buscar os dados');
      console.error(err);
    } finally {
      setAvarageTicketLoading(false);
    }
  };

  const fetchAllData = async () => {
    const promises = [
      fetchOrcamentOS(),
      fetchTickets(),
      fetchItemsSold(),
      fetchRevenueGraph(),
      fetchAverageTicketGraph(),
    ];
    try {
      await Promise.all(promises);
    } catch (err) {
      toastr.error('Ocorreu um erro ao buscar os dados');
      console.error(err);
    }
  };

  const fetchCardsData = async () => {
    const promises = [fetchOrcamentOS(), fetchTickets(), fetchItemsSold()];
    try {
      await Promise.all(promises);
    } catch (err) {
      toastr.error('Ocorreu um erro ao buscar os dados');
      console.error(err);
    }
  };

  const goToPreviousMonth = () => {
    const newDate = new Date(currentDate);
    newDate.setMonth(newDate.getMonth() - 1);
    setCurrentDate(newDate);
    setSelectedMonth(newDate.getMonth() + 1);
    setSelectedYear(newDate.getFullYear());
    updateCurrentDate(newDate);
  };

  const goToNextMonth = () => {
    const newDate = new Date(currentDate);
    newDate.setMonth(newDate.getMonth() + 1);
    setCurrentDate(newDate);
    setSelectedMonth(newDate.getMonth() + 1);
    setSelectedYear(newDate.getFullYear());
    updateCurrentDate(newDate);
  };

  useEffect(() => {
    if (companyId) {
      fetchAllData();
    }
  }, [companyId]);

  useEffect(() => {
    if (companyId) {
      fetchCardsData();
    }
  }, [selectedMonth]);

  useEffect(() => {
    if (companyId) {
      fetchRevenueGraph();
    }
  }, [revenuePeriod]);

  useEffect(() => {
    if (companyId) {
      fetchAverageTicketGraph();
    }
  }, [avarageTicketPeriod]);

  return (
    <ContainerSalesScreen>
      <ContainerHeaderCardMonth>
        <div style={{ display: 'flex' }}>
          <ButtonPreviousOrNext
            onClick={goToPreviousMonth}
            disabled={isLoading}
          >
            <FontAwesomeIcon
              icon={faChevronLeft}
              style={{ width: '16px', height: '16px', color: '#1D7391' }}
            />
          </ButtonPreviousOrNext>
          <ButtonPreviousOrNext onClick={goToNextMonth} disabled={isLoading}>
            <FontAwesomeIcon
              icon={faChevronRight}
              style={{ width: '16px', height: '16px', color: '#1D7391' }}
            />
          </ButtonPreviousOrNext>
          <span
            style={{
              fontSize: '16px',
              fontWeight: '900',
              textTransform: 'uppercase',
              color: '#1D7391',
              ...(isLoading && {
                opacity: 0.6,
                background: 'transparent',
                border: 0,
                cursor: 'not-allowed',
              }),
              ...(isLoading && {
                '&:hover:not(:disabled)': {
                  filter: 'brightness(85%)',
                },
              }),
            }}
          >
            {currentDate.toLocaleString('pt-BR', {
              month: 'long',
              year: 'numeric',
            })}
          </span>
        </div>
      </ContainerHeaderCardMonth>
      <ContainerContentCard>
        <div className="flex row gap-1">
          <div>
            <TextHeaderCardTitle>ORÇAMENTOS</TextHeaderCardTitle>
            <IndicatorCard
              title="Em aberto"
              value={orcaments?.totalValueOpenOrcament}
              showTotalBadge
              total={orcaments?.countOpenOrcament}
              secondaryTitle={'Cancelados'}
              secondaryValue={orcaments?.totalValueCanceledOrcament}
              secondaryTotal={orcaments?.countCanceledOrcament}
              type="double"
              variant="white"
              titleColor="gray"
              textColor="indigo"
              loading={isLoading}
            />
          </div>
          <div>
            <TextHeaderCardTitle>ORDENS DE SERVIÇO</TextHeaderCardTitle>
            <IndicatorCard
              title="Em aberto"
              value={orderServices?.totalValueOpenOS}
              showTotalBadge
              total={orderServices?.countOpenOS}
              secondaryTitle={'Cancelados'}
              secondaryValue={orderServices?.totalValueCanceledOS}
              secondaryTotal={orderServices?.countCanceledOS}
              type="double"
              variant="white"
              titleColor="gray"
              textColor="red"
              loading={isLoading}
            />
          </div>
        </div>
        <div className="flex row gap-1">
          <div>
            <TextHeaderCardTitle>TICKET MÉDIO</TextHeaderCardTitle>
            <IndicatorCard
              title="Ordem de Serviço"
              value={orderServicesTickets}
              secondaryTitle={'Vendas/PDV'}
              secondaryValue={salesPDVTickets}
              type="double"
              variant="indigo"
              loading={isLoading}
            />
          </div>
          <div>
            <TextHeaderCardTitle>ITENS VENDIDOS NO MÊS</TextHeaderCardTitle>
            <IndicatorCard
              title="Produtos"
              value={productsSold}
              secondaryTitle={'Serviços'}
              secondaryValue={servicesSold}
              type="double"
              variant="blue"
              loading={isLoading}
            />
          </div>
        </div>
      </ContainerContentCard>
      <ContainerContentCard>
        <ContainerGraph>
          <ContainerGraphHeader>
            <span
              style={{
                fontSize: '16px',
                fontWeight: '600',
                color: '#428BCA',
                textTransform: 'uppercase',
              }}
            >
              FATURAMENTO
            </span>
            <span
              style={{
                fontSize: '12px',
                fontWeight: '900',
                color: '#444444',
                textTransform: 'uppercase',
              }}
            >
              {formatDateRange(revenuePeriod.value)}
            </span>
            <InputSelectDefault
              style={{
                fontSize: '12px',
                borderColor: '#aaa',
                borderRadius: '8px',
              }}
              width={'150px'}
              options={[
                {
                  label: 'Últimos 6 meses',
                  value: 6,
                },
                {
                  label: 'Últimos 3 meses',
                  value: 3,
                },
              ]}
              value={revenuePeriod}
              fontSize={'12px'}
              onChange={(e) => setRevenuePeriod(e)}
              isLoading={revenueLoading}
            />
          </ContainerGraphHeader>
          <ContainerGraphContent>
            <IndicatorGraph
              data={revenueData}
              width={600}
              height={180}
              propertyLineA="totalProduct"
              propertyLineB="totalService"
              propertyLineX="referenceMonth"
              descriptionLineA="Produto"
              descriptionLineB="Serviço"
              isCurrency
              loading={revenueLoading}
            />
          </ContainerGraphContent>
        </ContainerGraph>
        <ContainerGraph>
          <ContainerGraphHeader>
            <span
              style={{
                fontSize: '16px',
                fontWeight: '600',
                color: '#428BCA',
                textTransform: 'uppercase',
              }}
            >
              TICKET MÉDIO
            </span>
            <span
              style={{
                fontSize: '12px',
                fontWeight: '900',
                color: '#444444',
                textTransform: 'uppercase',
              }}
            >
              {formatDateRange(avarageTicketPeriod.value)}
            </span>
            <InputSelectDefault
              style={{
                fontSize: '12px',
                borderColor: '#aaa',
                borderRadius: '8px',
              }}
              width={'150px'}
              options={[
                {
                  label: 'Últimos 6 meses',
                  value: 6,
                },
                {
                  label: 'Últimos 3 meses',
                  value: 3,
                },
              ]}
              value={avarageTicketPeriod}
              fontSize={'12px'}
              onChange={(e) => setAvarageTicketPeriod(e)}
              isLoading={avarageTicketLoading}
            />
          </ContainerGraphHeader>
          <ContainerGraphContent>
            <IndicatorGraph
              data={avarageTicketData}
              width={600}
              height={180}
              propertyLineA="totalProducts"
              propertyLineB="totalServices"
              propertyLineC="total"
              propertyLineX="referenceMonth"
              descriptionLineA="Produto"
              descriptionLineB="Serviço"
              descriptionLineC="Total"
              isCurrency
              loading={avarageTicketLoading}
            />
          </ContainerGraphContent>
        </ContainerGraph>
      </ContainerContentCard>
      <RankingIndicators />
    </ContainerSalesScreen>
  );
}
