import React, { useState } from 'react';
import CustomizedTabs from 'v2/components/Tabs/Tabs';
export function RankingIndicatorsNavigator() {
  const [selectedTab, setSelectedTab] = useState(0);
  function renderTabContent(index) {
    switch (index) {
      case 0:
        return <p>Teste1</p>;
      case 1:
        return <p>Teste2</p>;

      default:
        return null;
    }
  }
  const renderTabs = () => {
    let tabLabels = [
      'MECÂNICO E VENDEDORES',
      'VEÍCULOS E MONTADORAS',
      'CLIENTES',
      'SERVIÇOS E PRODUTOS',
    ];
    return (
      <CustomizedTabs
        tabLabels={tabLabels}
        value={selectedTab}
        onChange={handleTabChange}
      />
    );
  };
  function handleTabChange(event, newValue) {
    setSelectedTab(newValue);
  }
  return (
    <div>
      {renderTabs()}
      {renderTabContent(selectedTab)}
    </div>
  );
}
